import Api from "@/service/api";

export default class SalleService {
    salles() {
        return Api().get("/salles").then(res => res.data);
        }

    create(salle) {
        return Api().post("/salle", salle).then(res => res.data);
    }

    update(salle) {
        return Api().put("/salle/" + salle.salleId, salle).then(res => res.data);
    }

    delete(salle) {
        return Api().delete("/salle/" + salle.salleId).then(() => salle);
    }

    salle(salleId) {
        return Api().get("/salle/" + salleId).then(res => this.formatRes(res.data));
    }
}